import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useRef,
} from "react";
import {
  TextField,
  Button,
  Grid,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Delete as DeleteIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  SubdirectoryArrowRight as SubdirectoryArrowRightIcon,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Alert } from "@material-ui/lab";

interface SubItem {
  name: string;
  title: string;
  slug: string;
}

interface SingleMainItem {
  name: string;
  title: string;
  slug: string;
}

interface MainItemWithSubItems {
  heading: string;
  items: SubItem[];
}

type MainItem = SingleMainItem | MainItemWithSubItems;

interface PropertyValue {
  name: string;
  value_array?: string[];
  display_name: string;
}

interface MenuInputProps {
  index: number;
  propertyValue: PropertyValue;
  handleEditArray: (value: string[], index: number) => void;
  handleChange: (value: string, index: number) => void;
}

// Instances that have changed their frontend to remove "link" property.
const InstanceRecievedFix = [
  "getperforming-production",
  "theplumbingpros",
  "pure-plumbing-professionals",
  "innovate-energy-production",
  "forklogic-production",
  "moretonbayss-staging",
  "urbanenergy-production",
  "triforce-electrical-and-air-production",
  "alltronic",
  "sea-air",
  "grh-plumbing",
  "moretonbayss",
  "express-waster-water",
  "haddon-kitchens",
  "powerix"
];

const MenuInput: React.FC<MenuInputProps> = (props) => {
  const [newItemType, setNewItemType] = useState<"single" | "withSubItems">(
    "single"
  );
  const [newItem, setNewItem] = useState<SingleMainItem>({
    name: "",
    title: "",
    slug: "",
  });
  const [newSubItem, setNewSubItem] = useState<SubItem>({
    name: "",
    title: "",
    slug: "",
  });
  const [isValid, setIsValid] = useState<boolean>(true);
  const [newItemIsValid, setNewItemIsValid] = useState<boolean>(false);
  const newItemRef = useRef<HTMLDivElement | null>(null);

  const state = useSelector((state: RootState) => state);
  const instanceSlug = state.current_site.site?.slug as string;
  const isInstanceFixed = InstanceRecievedFix.includes(instanceSlug);

  useEffect(() => {
    validateAllItems();
  }, [props.propertyValue.value_array, newItem, newItemType]);

  const isSingleMainItem = (item: MainItem): item is SingleMainItem => {
    return (item as SingleMainItem).slug !== undefined;
  };

  const handleNewItemChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setNewItem((prevItem) => ({
        ...prevItem,
        [name]: value,
      }));
    },
    []
  );

  const handleAddNewItem = useCallback(() => {
    if (!newItemIsValid) return;

    const newItemString =
      newItemType === "single"
        ? JSON.stringify(newItem)
        : JSON.stringify({ heading: newItem.name, items: [] });

    props.handleChange(newItemString, props.index);
    setNewItem({ name: "", title: "", slug: "" });
    setTimeout(() => {
      if (newItemRef.current) {
        newItemRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }, [newItem, newItemType, props, newItemIsValid]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number,
      isSubItem: boolean = false,
      parentIndex: number | null = null
    ) => {
      const { name, value } = event.target;
      const updatedItems = (props.propertyValue.value_array ?? [])?.map(
        (itemString, idx) => {
          if (idx === index && !isSubItem) {
            const item = JSON.parse(itemString);
            if (isSingleMainItem(item)) {
              (item as any)[name] = value;
            } else {
              if (name === "heading") {
                item.heading = value;
              }
            }
            return JSON.stringify(item);
          } else if (isSubItem && idx === parentIndex) {
            const parentItem = JSON.parse(itemString);
            parentItem.items[index][name as keyof SubItem] = value;
            return JSON.stringify(parentItem);
          }
          return itemString;
        }
      );
      props.handleEditArray(updatedItems, props.index);
      validateAllItems();
    },
    [props]
  );

  const handleMove = useCallback(
    (
      fromIndex: number,
      toIndex: number,
      isSubItem: boolean = false,
      parentIndex: number | null = null
    ) => {
      const items = [...(props.propertyValue.value_array ?? [])];
      if (isSubItem) {
        const parentItem = JSON.parse(items[parentIndex!]);
        const [movedItem] = parentItem.items.splice(fromIndex, 1);
        parentItem.items.splice(toIndex, 0, movedItem);
        items[parentIndex!] = JSON.stringify(parentItem);
      } else {
        const [movedItem] = items.splice(fromIndex, 1);
        items.splice(toIndex, 0, movedItem);
      }
      props.handleEditArray(items, props.index);
    },
    [props]
  );

  const handleItemDelete = useCallback(
    (
      index: number,
      isSubItem: boolean = false,
      parentIndex: number | null = null
    ) => {
      const items = [...(props.propertyValue.value_array ?? [])];
      if (isSubItem) {
        const parentItem = JSON.parse(items[parentIndex!]);
        parentItem.items.splice(index, 1);
        items[parentIndex!] = JSON.stringify(parentItem);
      } else {
        items.splice(index, 1);
      }
      props.handleEditArray(items, props.index);
      validateAllItems();
    },
    [props]
  );

  const handleAddSubItem = useCallback(
    (parentIndex: number) => {
      const items = [...(props.propertyValue.value_array ?? [])];
      const parentItem = JSON.parse(items[parentIndex]);
      parentItem.items.push({ name: "", title: "", slug: "" });
      items[parentIndex] = JSON.stringify(parentItem);
      props.handleEditArray(items, props.index);
      validateAllItems();
    },
    [props]
  );

  const validateAllItems = useCallback(() => {
    const items = props.propertyValue.value_array ?? [];
    for (const itemString of items) {
      const item: MainItem = JSON.parse(itemString);
      if (isSingleMainItem(item)) {
        if (
          !item.name ||
          !item.title ||
          !item.slug ||
          !item.slug.startsWith("/") ||
          item.slug.endsWith("/")
        ) {
          setIsValid(false);
          return;
        }
      } else {
        if (!item.heading) {
          setIsValid(false);
          return;
        }
        for (const subItem of item.items) {
          if (
            !subItem.name ||
            !subItem.title ||
            !subItem.slug ||
            !subItem.slug.startsWith("/") ||
            subItem.slug.endsWith("/")
          ) {
            setIsValid(false);
            return;
          }
        }
      }
    }
    setIsValid(true);

    // Validate the new item fields
    if (newItemType === "single") {
      setNewItemIsValid(!!newItem.name && !!newItem.title && !!newItem.slug);
    } else {
      setNewItemIsValid(!!newItem.name);
    }
  }, [props.propertyValue.value_array, newItem, newItemType]);

  return (
    <Grid item xs={12}>
      {!isInstanceFixed && (
        <Alert severity="warning">
          Please note that the instance you are currently working on has not yet
          been updated to remove the "link" property from side menus. Please
          identify the instance and the specific menu you are editing, and let
          the dev team know. While you can make changes, be aware that doing so
          might break things, and you may lose work. If possible, it is
          advisable to wait until the dev team fixes the instance before making
          any edits. This will help ensure stability and prevent potential
          issues.
        </Alert>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        <FormControl
          variant="outlined"
          style={{ width: "16%", marginRight: 8 }}
        >
          <InputLabel>Item Type</InputLabel>
          <Select
            value={newItemType}
            onChange={(e) =>
              setNewItemType(e.target.value as "single" | "withSubItems")
            }
            label="Item Type"
          >
            <MenuItem value="single">Single Item</MenuItem>
            <MenuItem value="withSubItems">Item with Sub-items</MenuItem>
          </Select>
        </FormControl>
        <div style={{ display: "flex", alignItems: "center", width: "84%" }}>
          <TextField
            fullWidth
            variant="outlined"
            required
            label={newItemType === "single" ? "Name" : "Heading"}
            name="name"
            value={newItem.name}
            onChange={handleNewItemChange}
            style={{ marginRight: 8 }}
          />
          {newItemType === "single" && (
            <>
              <TextField
                fullWidth
                required
                variant="outlined"
                label="Title"
                name="title"
                value={newItem.title}
                onChange={handleNewItemChange}
                style={{ marginRight: 8 }}
              />
              <TextField
                fullWidth
                required
                variant="outlined"
                label="Slug"
                name="slug"
                value={newItem.slug || ""}
                onChange={handleNewItemChange}
                error={
                  !!(
                    newItem.slug &&
                    (!newItem.slug.startsWith("/") ||
                      newItem.slug.endsWith("/"))
                  )
                }
                helperText={
                  newItem.slug &&
                  (!newItem.slug.startsWith("/") || newItem.slug.endsWith("/"))
                    ? "Slug must start with '/' and not end with '/'"
                    : ""
                }
                style={{ marginRight: 8 }}
              />
            </>
          )}
          <Button
            style={{
              minWidth: "fit-content",
              padding: "14px 12px",
              whiteSpace: "nowrap",
            }}
            size="large"
            variant="outlined"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddNewItem}
            disabled={!newItemIsValid}
          >
            Add Item
          </Button>
        </div>
      </div>
      {(props.propertyValue.value_array ?? [])?.map((itemString, index) => {
        const item: MainItem = JSON.parse(itemString);
        return (
          <div
            key={index}
            style={{ marginBottom: 16 }}
            ref={
              index === props.propertyValue.value_array!.length - 1
                ? newItemRef
                : null
            }
          >
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
            >
              {isSingleMainItem(item) ? (
                <>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Name"
                    name="name"
                    value={item.name}
                    onChange={(e) => handleChange(e, index)}
                    style={{ marginRight: 8 }}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Title"
                    name="title"
                    value={item.title}
                    onChange={(e) => handleChange(e, index)}
                    style={{ marginRight: 8 }}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Slug"
                    name="slug"
                    value={item.slug || ""}
                    onChange={(e) => handleChange(e, index)}
                    error={
                      !!(
                        item.slug &&
                        (!item.slug.startsWith("/") || item.slug.endsWith("/"))
                      )
                    }
                    helperText={
                      item.slug &&
                      (!item.slug.startsWith("/") || item.slug.endsWith("/"))
                        ? "Slug must start with '/' and not end with '/'"
                        : ""
                    }
                    style={{ marginRight: 8 }}
                  />
                </>
              ) : (
                <TextField
                  required
                  variant="outlined"
                  label="Heading"
                  name="heading"
                  fullWidth
                  value={item.heading}
                  onChange={(e) => handleChange(e, index)}
                  style={{ marginRight: 8 }}
                />
              )}

              <IconButton
                onClick={() => handleMove(index, index - 1)}
                disabled={index === 0}
              >
                <KeyboardArrowUpIcon />
              </IconButton>
              <IconButton
                onClick={() => handleMove(index, index + 1)}
                disabled={
                  index === (props.propertyValue.value_array ?? []).length - 1
                }
              >
                <KeyboardArrowDownIcon />
              </IconButton>
              <IconButton onClick={() => handleItemDelete(index)}>
                <DeleteIcon />
              </IconButton>
            </div>
            {!isSingleMainItem(item) && (
              <>
                {item?.items?.map((subItem, subIndex) => (
                  <div
                    key={subIndex}
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      marginTop: 16,
                    }}
                  >
                    <SubdirectoryArrowRightIcon
                      style={{ marginLeft: 16, marginRight: 16 }}
                    />
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      label="Name"
                      name="name"
                      value={subItem.name}
                      onChange={(e) => handleChange(e, subIndex, true, index)}
                      style={{ marginRight: 8 }}
                    />
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      label="Title"
                      name="title"
                      value={subItem.title}
                      onChange={(e) => handleChange(e, subIndex, true, index)}
                      style={{ marginRight: 8 }}
                    />
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      label="Slug"
                      name="slug"
                      value={subItem.slug || ""}
                      onChange={(e) => handleChange(e, subIndex, true, index)}
                      error={
                        !!(
                          subItem.slug &&
                          (!subItem.slug.startsWith("/") ||
                            subItem.slug.endsWith("/"))
                        )
                      }
                      helperText={
                        subItem.slug &&
                        (!subItem.slug.startsWith("/") ||
                          subItem.slug.endsWith("/"))
                          ? "Slug must start with '/' and not end with '/'"
                          : ""
                      }
                      style={{ marginRight: 8 }}
                    />

                    <IconButton
                      onClick={() =>
                        handleMove(subIndex, subIndex - 1, true, index)
                      }
                      disabled={subIndex === 0}
                    >
                      <KeyboardArrowUpIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        handleMove(subIndex, subIndex + 1, true, index)
                      }
                      disabled={subIndex === item.items.length - 1}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleItemDelete(subIndex, true, index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
                <Button
                  style={{ marginLeft: 56, marginTop: 16 }}
                  variant="outlined"
                  color="primary"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => handleAddSubItem(index)}
                >
                  Add Sub-item
                </Button>
              </>
            )}
          </div>
        );
      })}
    </Grid>
  );
};

export default MenuInput;
