import React, { useState } from "react";
import { useHistory, useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import slugify from "slugify";
import CREATE_COLLECTION from "../../../graphql/CREATE_COLLECTION";
import CREATE_GRAPHQL_QUERY_AND_PAGE from "../../../graphql/CREATE_GRAPHQL_QUERY_AND_PAGE";
import GET_COLLECTION_CONTENT_TYPES from "../../../graphql/GET_COLLECTION_CONTENT_TYPES";
import GET_PUBLIC_READ_TABLES from "../../../graphql/GET_PUBLIC_READ_TABLES";
import generateGraphQueryBody from "../../../utils/createGraphQueryBody";
import * as Routes from "../../../routes";
import { RootState } from "../../../store/store";
import CollectionsFormView from "../../../components/collections/CollectionsFormView";
import ErrorToast from "../../../components/layout/ErrorToast";
import generateGetAPIPageBody from "../../../utils/createGetAPIPageBody";
import FormLayout from "../../../components/layout/FormLayout";

const CollectionCreate = () => {
  let history = useHistory();
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  const [collectionFilePath, setCollectionFilePath] = useState("");
  const [collectionTableName, setCollectionTableName] = useState("");
  const [collectionMetadata, setcollectionMetadata] =
    useState<CollectionMetadata>({
      display_name: "",
      description: "",
      public_read: true,
      public_write: false,
      public_delete: false,
      collection: true,
      collection_url: "",
    });
  const [collectionProperties, setCollectionProperties] = useState<
    CollectionProperty[]
  >([]);

  const [
    createCollection,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_COLLECTION, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const newContentType = data?.admin_table_create;
      const existingContentTypes: any = cache.readQuery({
        query: GET_COLLECTION_CONTENT_TYPES,
      });
      const existingAllContentTypes: any = cache.readQuery({
        query: GET_PUBLIC_READ_TABLES,
      });

      if (newContentType && existingContentTypes) {
        cache.writeQuery({
          query: GET_COLLECTION_CONTENT_TYPES,
          data: {
            admin_tables: {
              results: [
                ...existingContentTypes?.admin_tables.results,
                newContentType,
              ],
            },
          },
        });
      }
    },
    onCompleted: (data) => {
      if (collectionMetadata.public_read) {
        const queryBody = generateGraphQueryBody(
          collectionTableName,
          collectionProperties
        );
        const pageContent = generateGetAPIPageBody(
          collectionTableName,
          collectionProperties
        );
        const queryPath = `graphql/${collectionTableName}_get.graphql`;
        const pagePath = `views/pages/rsc/${collectionTableName}.liquid`;
        createQuery({
          variables: {
            query_file_path: queryPath,
            body: queryBody,
            page_file_path: pagePath,
            slug: `rsc/${collectionTableName}`,
            content: pageContent,
          },
        });
      } else if (collectionMetadata.public_write) {
        if (data) {
          history.push(Routes.COLLECTION_LINK(params.site));
        }
      } else {
        if (data) {
          history.push(Routes.COLLECTION_LINK(params.site));
        }
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [
    createQuery,
    {
      data: mutationQueryData,
      loading: mutationQueryLoading,
      error: mutationQueryError,
    },
  ] = useMutation(CREATE_GRAPHQL_QUERY_AND_PAGE, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    onCompleted: (data) => {
      if (collectionMetadata.public_write) {
        if (data) {
          history.push(Routes.COLLECTION_LINK(params.site));
        }
      } else {
        if (data) {
          history.push(Routes.COLLECTION_LINK(params.site));
        }
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name: string = event.target.value;
    setcollectionMetadata({
      ...collectionMetadata,
      display_name: name,
    });

    const slugified = slugify(name, {
      replacement: "_",
      lower: true,
      trim: true,
    });
    setCollectionTableName(slugified);
    setCollectionFilePath(`schema/${slugified}.yml`);
  };

  const handleMetadataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setcollectionMetadata({
      ...collectionMetadata,
      [event.target.name]: value,
    });
  };

  const addCollectionProperty = (
    collectionProperty: CollectionProperty,
    propertyMetadata: PropertyMetadata
  ) => {
    const property: CollectionProperty = {
      ...collectionProperty,
      metadata: propertyMetadata,
    };

    setCollectionProperties([...collectionProperties, property]);
  };

  const removeCollectionProperty = (index: number) => {
    const filteredProperties = collectionProperties.filter((property, i) => {
      if (index !== i) {
        return property;
      }
    });

    setCollectionProperties(filteredProperties);
  };

  const editCollectionProperty = (
    property: CollectionProperty,
    propertyMetadata: PropertyMetadata,
    index: number
  ) => {
    const newProperty: CollectionProperty = {
      ...property,
      metadata: propertyMetadata,
    };

    const editedProperties = collectionProperties.map((prop, i) => {
      if (index === i) {
        return newProperty;
      }
      return prop;
    });

    setCollectionProperties(editedProperties);
  };

  const handleSubmit = () => {
    createCollection({
      variables: {
        physical_file_path: collectionFilePath,
        metadata: collectionMetadata,
        properties: collectionProperties,
      },
    });
  };

  return (
    <FormLayout
      loading={mutationLoading || mutationQueryLoading}
      formHeading="Create Collection"
      backLink={Routes.COLLECTION_LINK(params.site)}
      size="md"
    >
      <CollectionsFormView
        collectionMetadata={collectionMetadata}
        collectionProperties={collectionProperties}
        handleDisplayNameChange={handleDisplayNameChange}
        handleMetadataChange={handleMetadataChange}
        setCollectionProperties={setCollectionProperties}
        filePath={collectionFilePath}
        addCollectionProperty={addCollectionProperty}
        removeCollectionProperty={removeCollectionProperty}
        editCollectionProperty={editCollectionProperty}
        handleSubmit={handleSubmit}
      />
      {mutationError ? (
        <ErrorToast open={true} errorMessage={mutationError.message} />
      ) : null}
    </FormLayout>
  );
};

interface SiteParams {
  site: string;
}

export interface CollectionMetadata {
  display_name: string;
  description: string;
  public_read: boolean;
  public_write: boolean;
  public_delete: boolean;
  collection?: boolean;
  single?: boolean;
  collection_url?: string;
}

export interface CollectionProperty {
  name: string;
  belongs_to?: string;
  attribute_type: string | null;
  metadata: PropertyMetadata | null;
  __typename?: string;
}

export interface PropertyMetadata {
  display_name: string;
  type: string;
  private: boolean;
  display_priority: number;
  required: boolean;
  table_display: boolean;
}

export default CollectionCreate;
