import React from 'react';
import { TextField, InputLabel, Grid } from '@material-ui/core';
import { PropertyValue } from '../../pages/site/entities/create';

const TextInput = (props: TextAreaInputProps) => {

    return(

        <Grid item xs={12} lg={6}>
            <p className="entity__inputHeading">{props.propertyValue.display_name}</p>
            <TextField 
               fullWidth
                value={props.propertyValue.value}
                onChange={(event) => props.handleChange((event.target.value as string), props.index)}
                variant="outlined"
                placeholder="Text Input"
            />
        </Grid>
    );
}

interface TextAreaInputProps {
    index: number
    propertyValue: PropertyValue
    handleChange: (value: string, index: number) => void
}

export default TextInput;