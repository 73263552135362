import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Switch, Route, Redirect, NavLink } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import SideMenu from "../../components/layout/siteManagerMenu";
import BuildButton from "../../components/layout/siteManagerMenu/BuildButton";
import * as Routes from "../../routes";
import Dashboard from "./dashboard";
import Pages from "./pages";
import PageCreate from "./pages/create";
import PageUpdate from "./pages/update";
import Redirects from "./redirects";
import RedirectCreate from "./redirects/create";
import RedirectUpdate from "./redirects/update";
import Assets from "./assets";
import Constants from "./constants";
import ConstantCreate from "./constants/create";
import ConstantUpdate from "./constants/update";
import Layouts from "./layouts";
import LayoutCreate from "./layouts/create";
import LayoutUpdate from "./layouts/update";
import Partials from "./partials";
import PartialCreate from "./partials/create";
import PartialUpdate from "./partials/update";
import Content from "./collection";
import ContentCreate from "./collection/create";
import ContentUpdate from "./collection/update";
import SingleCollection from "./collection/view";
import EntityCreate from "./entities/create";
import Forms from "./forms";
import FormCreate from "./forms/create";
import FormUpdate from "./forms/update";
import InfoPlugin from "./plugins/info";
import InfoCreate from "./plugins/info/create";
import InfoUpdate from "./plugins/info/update";
import CityCreate from "./plugins/areas/city/create";
import City from "./plugins/areas/city";
import CityContent from "./plugins/areas/city/content";
import CityContentCreate from "./plugins/areas/city/content/create";
import RegionCreate from "./plugins/areas/region/create";
import Region from "./plugins/areas/region";
import RegionContent from "./plugins/areas/region/content";
import RegionContentCreate from "./plugins/areas/region/content/create";
import SuburbCreate from "./plugins/areas/suburb/create";
import Suburb from "./plugins/areas/suburb";
import SuburbContent from "./plugins/areas/suburb/content";
import SuburbContentCreate from "./plugins/areas/suburb/content/create";
import SuburbDefault from "./plugins/areas/suburb/default";
import SuburbDefaultCreate from "./plugins/areas/suburb/default/create";
import SingleForm from "./forms/view";
import Single from "./singles/index";
import SingleCreate from "./singles/create";
import SingleUpdateFields from "./singles/updateFields";
import SingleUpdateItem from "./singles/updateItem";
import Users from "./users";
import Webforms from "./webforms";
import WebformView from "./webforms/view";
import { fetchSiteInfoIfNeeded } from "../../store/currentSite/actions";
import { RootState } from "../../store/store";
import logo from "../../assets/WaveCMS_Logo_Text.svg";
import { useTheme, Theme, createStyles } from "@material-ui/core/styles";
import Avatar from "../../components/layout/User";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";

import { Typography, Drawer, Button, Divider } from "@material-ui/core";
import Logs from "./logs";
const drawerWidth = 240;

interface ParamTypes {
  site: string;
}

interface Props {
  window?: () => Window;
}

function Site(props: Props) {
  const { site } = useParams<ParamTypes>();
  const classes = useStyles();
  const state = useSelector((state: RootState) => state);
  const currentSite = state.current_site;
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { window } = props;
  useEffect(() => {
    dispatch(fetchSiteInfoIfNeeded(site, client));
  }, [dispatch, site, state.current_user.user]);

  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className={classes.drawer_wrapper}>
      <div>
        <div className={classes.drawerHeader}>
          <Button
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
              textTransform: "none",
              minHeight: "64px",
            }}
            component={NavLink}
            to={Routes.INSTANCE_PORTAL}
          >
            {/* <img src={logo} alt="WaveCRM Logo" /> */}
            <Typography variant="h3" color="textPrimary">
              Smartyr<span style={{ color: "#1565c0" }}>DXP</span>
            </Typography>
          </Button>
        </div>
        <Divider />
        <SideMenu />
      </div>
      <div className={classes.drawer_footer}>
        <BuildButton />
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          component={NavLink}
          to={Routes.SITE_LOGS_LINK(site)}
        >
          View Logs
        </Button>
        {/* <Avatar /> */}
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <div className={classes.root}>
        {currentSite.fetching ? (
          <></>
        ) : currentSite.site ? (
          <div className={classes.root}>
            <CssBaseline />

            <nav className={classes.drawer} aria-label="Navigation Drawer">
              <Hidden smUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
            <main className={classes.content}>
              <div />
              <Switch>
                <Route
                  path={Routes.SINGLE_EDIT_ITEM}
                  component={SingleUpdateItem}
                />
                <Route
                  path={Routes.SINGLE_EDIT_FIELDS}
                  component={SingleUpdateFields}
                />
                <Route path={Routes.SITE_LOGS} component={Logs} />
                <Route path={Routes.SINGLE_CREATE} component={SingleCreate} />
                <Route path={Routes.SITE_SINGLES} component={Single} />
                <Route path={Routes.FORM_EDIT} component={FormUpdate} />
                <Route path={Routes.FORM_CREATE} component={FormCreate} />
                <Route path={Routes.SITE_FORM_VIEW} component={SingleForm} />
                <Route path={Routes.SITE_FORMS} component={Forms} />
                <Route path={Routes.ENTITY_EDIT} component={EntityCreate} />
                <Route path={Routes.ENTITY_CREATE} component={EntityCreate} />
                <Route
                  path={Routes.COLLECTION_EDIT}
                  component={ContentUpdate}
                />
                <Route
                  path={Routes.COLLECTION_CREATE}
                  component={ContentCreate}
                />
                <Route
                  path={Routes.SITE_COLLECTION_VIEW}
                  component={SingleCollection}
                />
                <Route path={Routes.SITE_COLLECTIONS} component={Content} />
                <Route path={Routes.PAGE_CREATE} component={PageCreate} />
                <Route path={Routes.PAGE_EDIT} component={PageUpdate} />
                <Route path={Routes.SITE_PAGES} component={Pages} />
                <Route
                  path={Routes.REDIRECT_CREATE}
                  component={RedirectCreate}
                />
                <Route path={Routes.REDIRECT_EDIT} component={RedirectUpdate} />
                <Route path={Routes.SITE_REDIRECTS} component={Redirects} />
                <Route path={Routes.LAYOUT_CREATE} component={LayoutCreate} />
                <Route path={Routes.LAYOUT_EDIT} component={LayoutUpdate} />
                <Route path={Routes.SITE_LAYOUT} component={Layouts} />
                <Route
                  path={Routes.PARTIALS_CREATE}
                  component={PartialCreate}
                />
                <Route path={Routes.PARTIALS_EDIT} component={PartialUpdate} />
                <Route path={Routes.SITE_PARTIALS} component={Partials} />
                <Route
                  path={Routes.CONSTANT_CREATE}
                  component={ConstantCreate}
                />
                <Route path={Routes.CONSTANT_EDIT} component={ConstantUpdate} />
                <Route path={Routes.SITE_CONSTANTS} component={Constants} />
                <Route path={Routes.SITE_ASSETS} component={Assets} />
                <Route path={Routes.SITE_USERS} component={Users} />
                <Route
                  path={Routes.SITE_WEBFORM_VIEW}
                  component={WebformView}
                />
                <Route path={Routes.SITE_WEBFORMS} component={Webforms} />

                <Route path={Routes.INFO_CREATE} component={InfoCreate} />
                <Route path={Routes.INFO_EDIT} component={InfoUpdate} />
                <Route path={Routes.INFO} component={InfoPlugin} />

                <Route
                  path={Routes.SUBURB_DEFAULT_CONTENT_EDIT}
                  component={SuburbDefaultCreate}
                />
                <Route
                  path={Routes.SUBURB_DEFAULT_CONTENT_CREATE}
                  component={SuburbDefaultCreate}
                />
                <Route path={Routes.SUBURB_DEFAULT} component={SuburbDefault} />
                <Route
                  path={Routes.SUBURB_CONTENT_EDIT}
                  component={SuburbContentCreate}
                />
                <Route
                  path={Routes.SUBURB_CONTENT_CREATE}
                  component={SuburbContentCreate}
                />
                <Route path={Routes.SUBURB_CONTENT} component={SuburbContent} />
                <Route path={Routes.SUBURB_EDIT} component={SuburbCreate} />
                <Route path={Routes.SUBURB_CREATE} component={SuburbCreate} />
                <Route path={Routes.SUBURB} component={Suburb} />

                <Route
                  path={Routes.REGION_CONTENT_EDIT}
                  component={RegionContentCreate}
                />
                <Route
                  path={Routes.REGION_CONTENT_CREATE}
                  component={RegionContentCreate}
                />
                <Route path={Routes.REGION_CONTENT} component={RegionContent} />
                <Route path={Routes.REGION_EDIT} component={RegionCreate} />
                <Route path={Routes.REGION_CREATE} component={RegionCreate} />
                <Route path={Routes.REGION} component={Region} />

                <Route
                  path={Routes.CITY_CONTENT_EDIT}
                  component={CityContentCreate}
                />
                <Route
                  path={Routes.CITY_CONTENT_CREATE}
                  component={CityContentCreate}
                />
                <Route path={Routes.CITY_CONTENT} component={CityContent} />
                <Route path={Routes.CITY_EDIT} component={CityCreate} />
                <Route path={Routes.CITY_CREATE} component={CityCreate} />
                <Route path={Routes.CITY} component={City} />

                <Route exact path={Routes.SITE} component={Dashboard} />
              </Switch>
            </main>
          </div>
        ) : (
          <div>Error no site found</div>
        )}
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer_wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },

    toolbar_wrap: {
      display: "flex",
      background: "#333",
      color: "white",
      justifyContent: "space-between",
    },
    toolbar_left: {
      alignItems: "center",
      display: "flex",
      marginRight: "16px",
    },
    drawer_footer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: theme.spacing(1),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    hide: {
      display: "none",
    },

    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },

    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },

    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      backgroundColor: "#333",
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    root: {
      display: "flex",
      width: "100%",
    },

    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3),
      },
    },
  })
);

export default Site;
