import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import GET_SINGLES from "../../../graphql/GET_SINGLE_CONTENT_TYPES";
import * as Routes from "../../../routes";
import DataTable from "../../../components/dataTable";

import ActionCell from "../../../components/dataTable/singlesActionCell";
import { RootState } from "../../../store/store";

import TableLayout from "../../../components/layout/TableLayout";
import EmptyContentMessage from "../../../components/layout/EmptyContentMessage";

function SingleTypes() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  interface SiteParams {
    site: string;
  }

  const { loading, error, data } = useQuery(GET_SINGLES, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const singles: any = useMemo(() => {
    return data ? data.admin_tables.results : [];
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "metadata.display_name",
      },
      {
        Header: "Unique ID",
        accessor: "name",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "",
        accessor: "parameterized_name",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              value={props.value}
              slug={props.value}
            />
          );
        },
      },
    ],
    []
  );
  return (
    <TableLayout
      loading={loading}
      buttonLink={Routes.SINGLE_CREATE_LINK(params.site)}
      tableHeading="All Singles"
      buttonText="Create Single"
    >
      {singles.length === 0 ? (
        <EmptyContentMessage message="This instance does not have any singles" />
      ) : (
        <DataTable data={singles} columns={columns} />
      )}
    </TableLayout>
  );
}

export default SingleTypes;
