import { Link as RouterLink } from "react-router-dom";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import * as Routes from "../../routes";
import FavouriteButton from "../layout/FavouriteButton";

const PagesActionCell = (props) => {
  return (
    <div className="action_cell">
      <Tooltip title="Edit Single">
        <IconButton
          component={RouterLink}
          to={Routes.SINGLE_EDIT_ITEM_LINK(props.site, props.value)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <FavouriteButton type="single" slug={props.slug} />
    </div>
  );
};

export default PagesActionCell;
